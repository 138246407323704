<template>
  <AuthFormWrapper>
    <template #formTitle>
      Register your account
    </template>
    <template #formSubtitle>
      Enter a password below to complete account registration.
    </template>

    <form
      @submit="onSubmit"
      class="w-full mt-12 lg:mt-16"
    >
      <BaseErrorMessage 
        v-if="submissionError"
        extend-wrapper-classes="-mt-6 mb-4"
      >
        <span v-html="submissionError" />
      </BaseErrorMessage>
      
      <fieldset :disabled="working">
        <!-- Fields -->
        <VTextInput 
          name="email"
          type="email"
          label="Email Address"
          :value="email"
          readonly
        />
        <VTextInput 
          name="password"
          type="password"
          label="Password"
          minlength="8"
          maxlength="50"
        />
        <VTextInput 
          name="password_confirmation"
          type="password"
          label="Confirm Password"
          maxlength="50"
        />
      </fieldset>

      <!-- Submit -->
      <BaseButton
        theme="auth"
        size="full"
        type="submit"
        :working="working"
      >
        Register Account
      </BaseButton>
    </form>
  </AuthFormWrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AuthApi from '@/apis/rota-architect/auth'
import { useRoute, useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import useToasts from '@/hooks/useToasts'
import * as yup from 'yup'
import parseErrorMap from '@/utils/parseErrorMap'

// Store
import { useStore } from 'vuex'
import { ActionTypes as AuthActions } from '@/store/auth/actions'
import { GetterTypes as AuthGetters } from '@/store/auth/getters'
import { GetterTypes as UserGetters } from '@/store/user/getters'

// Types
import { Registerable, RegisterQuery } from '@/types/auth'

export default defineComponent({
  setup () {
    const store = useStore()
    const router = useRouter()
    const dispatch = useToasts()

    const working = ref(false)
    const submissionError = ref('')

    // extract token and user email from the route query
    const $route = useRoute()
    const { email, invite } = $route.query as RegisterQuery

    // init the reset form
    const resetSchema: yup.ObjectSchema<Registerable> = yup.object({
      email: yup
        .string()
        .email('Please provide a valid email.')
        .required('This field is required.'),
      password: yup
        .string()
        .max(25)
        .required('This field is required.'),
      password_confirmation: yup
        .string()
        .max(25)
        .oneOf([yup.ref('password'), ''], 'Password does not match.')
        .required('This field is required.'),
      invite_token: yup
        .string(),
    }).defined()

    const { handleSubmit } = useForm({
      validationSchema:  resetSchema,
    })
    
    // handle reset
    const onSubmit = handleSubmit((values, actions) => {
      submissionError.value = ''
      working.value = true

      // add token to payload.
      values.invite_token = invite

      AuthApi.register(values)
        .then(() => {
          // attempt login
          store.dispatch(AuthActions.ATTEMPT_LOGIN, {
            email: values.email,
            password: values.password,
          })
            .then(() => {
              if (store.getters[AuthGetters.IS_AUTH]) {
                dispatch.successToast(
                  `Welcome ${store.getters[UserGetters.USER].name}`,
                  'Registration successful',
                )
                router.push({ name: 'my-profile' })
              }
            })
          actions.resetForm()
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data) || 'Bad request'
        })
        .finally(() => {
          working.value = false
        })
    });


    return {
      email,
      working,
      onSubmit,
      submissionError,
    };
  },

  // check that visiting page with required query params.
  beforeRouteEnter(to, _, next) {			
      const { email, invite } = to.query
      
			if (!email || !invite) {
        next({ name: 'login' })
        return
      }  

      next()
		},
})
</script>